<template>
  <div class="Feed">
    <Navigator />
    <div class="feedbody dflx">
      <div class="feed-c">
        <div v-if="blogs !== 'No se encontraron coincidencias.'">
          <div class="items-c">
            <!-- {{ blogs }} -->
            <div v-for="(b, i) in blogs" :key="i" class="itm">
              <div class="post-c">
                <a :href="'/blog-post/' + b.url">
                  <img :src="urlpath.url() + '/blog-img/' + b.image
          " alt="post" class="post-img" />
                </a>
                <a :href="'/blog-post/' + b.url">
                  <div class="info-c">
                    <p class="title">{{ b.title }}</p>
                    <p class="date">{{ b.dateblog }}</p>
                  </div>
                </a>
                <a :href="'/blog-post/' + b.url" class="btn">
                  <p>LEER MÁS</p>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="val_no" v-else>BLOGS VACÍO</div>
      </div>
      <div class="widget-c">
        <WebSMenu />
      </div>

    </div>

    <Footer />
  </div>
</template>
<script>
import { mapActions } from "vuex";
import Navigator from "../components/items-page/Navigator";
import Footer from "../components/items-page/Footer";
import WebSMenu from "../components/items-page/WebSMenu";
let urlpath = require("../global/url");
export default {
  name: "Home",
  components: {
    Navigator,
    Footer,
    WebSMenu,
  },
  data() {
    return {
      status: "",
      message: "",
      urlpath,
    };
  },
  created() {
    this.getAllInfoBlo("blog");
  },
  computed: {
    blogs() {
      let value = this.$store.getters["blog/data"].filter(element => element.statusblog !== 'OCULTO');
      return value;
    },
  },
  methods: {
    ...mapActions("blog", ["getAllInfoBlo"]),
  },
}
</script>
<style>
.widget-c {
  display: none;
}

.feedbody {
  display: flex;
  justify-content: center;
}

@media (min-width: 768px) {
  .widget-c {
    display: block;
    padding-left: 3.125vw;
  }
}
</style>